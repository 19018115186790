var key;
if (process.env.NODE_ENV == "production") {
    key = {
        baseurl: "https://jugalswapapi.wealwin.com",
        txUrl: "https://testnet.bscscan.com/tx/",
        masterchef:"0x7b2421bEa68bb2CA89236eCD06Ea1C9069A5F42d",
        factory:"0x51e766e4f51e303b14b88dE3a3b7DfBA496CE1bF",
        ifocontract:"0x177B7731D8fd4F286f44C4DD451d5657058b9F9f"

    }
}
else {
    key = {
        baseurl: "http://localhost:2743",
        txUrl: "https://testnet.bscscan.com/tx/",
        masterchef:"0x7b2421bEa68bb2CA89236eCD06Ea1C9069A5F42d",
        factory:"0x51e766e4f51e303b14b88dE3a3b7DfBA496CE1bF",
        ifocontract:"0x177B7731D8fd4F286f44C4DD451d5657058b9F9f"
    }
}
export default key;