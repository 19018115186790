function formatAddress(address) {
    try {
        var addr = address.substring(0, 10);
        var addr1 = address.substring(35, 42);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}

export default formatAddress;