import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import moment from 'moment';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import config from "../../lib/config";
import convert from "../../lib/converter"
import formatAddress from "../../lib/formatAddress";
var backurl = config.baseurl;


class Commision extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "from",
                text: "From",
                className: "from",
                align: "left",
                sortable: true,
                cell: record => {
                    var addr = this.formatAddress(record.from)
                    return addr;
                }
            },
            // {
            //     key: "to",
            //     text: "To",
            //     className: "to",
            //     align: "left",
            //     sortable: true,
            //     // cell: record => {
            //     //     var addr = formatAddress(record.to)
            //     //     return addr;
            //     // }
            // },
            
            {
                key: "txid",
                text: "Txid",
                className: "txid",
                align: "left",
                sortable: true,
                cell: record => {
                    var txUrl = config.txUrl + record.txid;
                    return (
                        <Fragment>
                            <a href={txUrl}
                                target="_blank"
                                style={{ marginRight: '5px' }}>
                                View
                            </a>
                        </Fragment>
                    );
                }
            },
            {
                key: "amount",
                text: "amount",
                className: "amount",
                align: "left",
                sortable: true,
                cell: record => {
                    var value = convert(record.amount)
                    var amount = value;
                    var amt = amount.toFixed(7);
                    return amt;
                }
            },
            {
                key: "createdAt",
                text: "Date",
                className: "createdAt",
                align: "left",
                sortable: true,
                cell: record => {
                    var createdAt = this.dateFormat(record.createdAt, 'DD-MM-YYYY');
                    return createdAt;
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Pools",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                from: "",
                to: "",
                txid: "",
                amount: "",
                tokensymbol: ""
            }
        };

        this.getData = this.getData.bind(this);
        this.formatAddress = this.formatAddress.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    formatAddress(address) {
        try {
            var addr = address.substring(0, 10);
            var addr1 = address.substring(35, 42);
            var concat = addr + "...." + addr1;
            return concat;
        } catch (err) {
            return "";
        }
    }

    dateFormat(date, format) {

        if (date && date !== '' && format && format !== '') {
            var cDate = moment(date).format(format);
            return cDate;
        } else {
            return '-';
        }
    }

    getData() {
        axios
            .post(backurl + "/api/commision-list")
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }

    pageChange(pageData) {
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Commission</h1>
                            {/* <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button> */}
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Commision.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Commision);
