import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import MasterchefABI from "../../ABI/MasterchefABI.json";
import factoryABI from "../../ABI/FactoryABI.json";

import config from "../../lib/config";

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class FormAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pid: "",
            risk: "",
            lpSymbol: "",
            alloc: "",
            isTokenOnly: "",
            lpAddresses: "",
            tokenSymbol: "",
            tokenAddresses: "",
            quoteTokenSymbol: "",
            quoteTokenAdresses: "",
            depositFee: "",
            imagepath: "",
            web3state: null,
            user: "",
            // masterContract:"",
            // factoryContract:"",
            file: {},
            errors: {},
        };
    }
    componentDidMount(props) {
        this.MetaMask()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-form-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    MetaMask = async (e) => {
        try {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                this.setState({ web3state: web3 })
                try {
                    window.ethereum.enable().then(async () => {
                        // User has allowed account access to DApp...
                        const accounts = await web3.eth.getAccounts();
                        console.log("Account : ", accounts[0]);
                        const data = accounts[0];
                        this.setState({ user: data });

                    });
                } catch (e) {
                    // User has denied account access to DApp...
                }
            }
            // Legacy DApp Browsers
            else if (window.web3) {
                const web3 = new Web3(window.web3.currentProvider);
                this.setState({ web3state: web3 })
            }
            // Non-DApp Browsers
            else {
                //alert('No Dapp Supported Wallet Found');
                console.log("No Dapp Supported Wallet Found")
            }
        } catch (e) {

        }

    }

    Validation() {

        var isForm = true;
        var errors = {}
        if (this.state.alloc == "" || !this.state.alloc) {
            errors.alloc = "Please enter the Multiplier";
            isForm = false;
        }
        if (this.state.tokenSymbol == "") {
            errors.tokenSymbol = "Please enter the token A";
            isForm = false;
        }
        if (this.state.quoteTokenSymbol == "") {
            errors.quoteTokenSymbol = "Please enter the token B";
            isForm = false;
        }
        if (this.state.tokenAddresses == "") {
            errors.tokenAddresses = "Please enter the Token A Address";
            isForm = false;
        }
        if (this.state.quoteTokenAdresses == "") {
            errors.quoteTokenAdresses = "Please enter the Token B Address";
            isForm = false;
        }
        if (this.state.depositFee == "") {
            errors.depositFee = "Please enter the deposit Fee";
            isForm = false;
        }
        this.setState({
            errors: errors
        });
        return isForm;

    }

    addFarmData = async (e) => {
        // console.log("web3 State : ",this.state.web3state)

        const ABI = MasterchefABI;
        const masterchef = config.masterchef;

        const FactoryABI = factoryABI;
        const factory = config.factory;


        var masterContract = await new this.state.web3state.eth.Contract(ABI, masterchef);
        var factoryContract = await new this.state.web3state.eth.Contract(FactoryABI, factory);

        var Symbol = this.state.tokenSymbol;
        var Addresses = this.state.tokenAddresses;
        var TokenSymbol = this.state.quoteTokenSymbol;
        var TokenAdresses = this.state.quoteTokenAdresses;
        var lp_Symbol = this.state.lpSymbol;

        if (this.Validation()) {

            this.setState({ tokenSymbol: Symbol.toUpperCase() });
            this.setState({ quoteTokenSymbol: TokenSymbol.toUpperCase() });
            this.setState({ lpSymbol: Symbol.toUpperCase() + ' - ' + TokenSymbol.toUpperCase() + ' LP' })

            var allocPoint = this.state.alloc;
            allocPoint = allocPoint * 100;

            var lpAddress = await factoryContract.methods.getPair(Addresses, TokenAdresses).call();
            var pid = await masterContract.methods.poolLength().call();

            var depositFee = this.state.depositFee * 100;

            this.setState({ lpAddresses: lpAddress });
            this.setState({ lpSymbol: lp_Symbol });
            this.setState({ pid: pid });

            try {
                var result = await masterContract.methods.add(
                    allocPoint,
                    this.state.lpAddresses,
                    depositFee,
                    false).send({ from: this.state.user })

                e.preventDefault();
                const newForm = {
                    pid: pid,
                    risk: this.state.risk,
                    alloc: this.state.alloc,
                    isTokenOnly: this.state.isTokenOnly,
                    lpAddresses: lpAddress,
                    tokenSymbol: this.state.tokenSymbol,
                    tokenAddresses: this.state.tokenAddresses,
                    quoteTokenSymbol: this.state.quoteTokenSymbol,
                    quoteTokenAdresses: this.state.quoteTokenAdresses,
                    depositFee: this.state.depositFee,
                    file: this.state.file
                };
                await this.props.addForm(newForm, this.props.history);
            } catch (err) {
                console.log('errerrerrerr', err)
                toast.error("Try Again", toasterOption);
            }

        }



    }
    onChange = e => {
        //console.log("value>>>>",e.target.value)
        this.setState({ [e.target.id]: e.target.value });
    };
    onFileChange = event => {
        var name = event.target.value;
        // Update the state
        this.setState({ file: event.target.files[0] });
        this.setState({ imagepath: name });
        //console.log(name);
    };


    clearData = async (e) => {

        this.setState({
            errors: {},
            alloc: "",
            lpAddresses: "",
            tokenSymbol: "",
            tokenAddresses: "",
            quoteTokenSymbol: "",
            quoteTokenAdresses: "",
            depositFee: "",
            imagepath: null,
            file: null
        });
    }


    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-form-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Farm</h4>
                                <button type="button" onClick={this.clearData} className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="add-form">
                                    <div className="row mt-2">

                                        <div className="col-md-3">
                                            <label htmlFor="alloc">Multipier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.alloc}
                                                placeholder="Multipier (x)"
                                                id="alloc"
                                                type="text"
                                                error={errors.alloc}
                                                className={classnames("form-control", {
                                                    invalid: errors.alloc
                                                })} />
                                            <span className="text-danger">{errors.alloc}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.pid}
                                                id="pid"
                                                type="hidden"
                                                error={errors.pid}
                                                className={classnames("form-control", {
                                                    invalid: errors.pid
                                                })} />
                                            <span className="text-danger">{errors.pid}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Token A Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenSymbol}
                                                placeholder="Enter Token Symbol"
                                                id="tokenSymbol"
                                                type="text"
                                                error={errors.tokenSymbol}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenSymbol
                                                })} />
                                            <span className="text-danger">{errors.tokenSymbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenSymbol">Token B Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Quote Token Symbol"
                                                value={this.state.quoteTokenSymbol}
                                                id="quoteTokenSymbol"
                                                type="text"
                                                error={errors.quoteTokenSymbol}
                                                className={classnames("form-control", {
                                                    invalid: errors.quoteTokenSymbol
                                                })} />
                                            <span className="text-danger">{errors.quoteTokenSymbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses"> Token A Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Token Address"
                                                value={this.state.tokenAddresses}
                                                error={errors.tokenAddresses}
                                                id="tokenAddresses"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenAddresses
                                                })}
                                            />
                                            <span className="text-danger">{errors.tokenAddresses}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Token B Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Quote Token Address"
                                                value={this.state.quoteTokenAdresses}
                                                error={errors.quoteTokenAdresses}
                                                id="quoteTokenAdresses"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.quoteTokenAdresses
                                                })}
                                            />
                                            <span className="text-danger">{errors.quoteTokenAdresses}</span>
                                        </div>


                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee"> Deposit Fee</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Deposit Fee"
                                                value={this.state.depositFee}
                                                error={errors.depositFee}
                                                id="depositFee"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.depositFee
                                                })} depositFee
                                            />
                                            <span className="text-danger">{errors.depositFee}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                // placeholder="Enter Deposit Fee"
                                                value={this.state.lpAddresses}
                                                error={errors.lpAddresses}
                                                id="lpAddresses"
                                                type="hidden"
                                                className={classnames("form-control", {
                                                    invalid: errors.lpAddresses
                                                })} depositFee
                                            />
                                            <span className="text-danger">{errors.lpAddresses}</span>
                                        </div>


                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Add Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.clearData} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-form"

                                    className="btn btn-primary" onClick={this.addFarmData}>
                                    Add Farm
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormAddModal.propTypes = {
    addForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addForm }
)(withRouter(FormAddModal));
