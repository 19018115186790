import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import MasterchefABI from "../../ABI/MasterchefABI.json";
import factoryABI from "../../ABI/FactoryABI.json";

import config from "../../lib/config";

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class FormUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: this.props.record._id,
            pid: this.props.record.pid,
            risk: this.props.record.risk,
            alloc: this.props.record.alloc,
            isTokenOnly: this.props.record.isTokenOnly,
            lpAddresses: this.props.record.lpAddresses,
            tokenSymbol: this.props.record.tokenSymbol,
            tokenAddresses: this.props.record.tokenAddresses,
            quoteTokenSymbol: this.props.record.quoteTokenSymbol,
            quoteTokenAdresses: this.props.record.quoteTokenAdresses,
            depositFee: this.props.record.depositFee,
            updated: this.props.record.updated,
            user: this.props.record.user,
            file: {},
            errors: {},
        };
        
    }
    componentDidMount(props) {
        console.log("data :", this.props.record)
        this.MetaMask()
    }

    MetaMask = async (e) => {
        try {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                this.setState({ web3state: web3 })
                try {
                    window.ethereum.enable().then(async () => {
                        // User has allowed account access to DApp...
                        const accounts = await web3.eth.getAccounts();
                        console.log("Account : ", accounts[0]);
                        const data = accounts[0];

                        this.setState({ user: data });

                    });
                } catch (e) {
                    // User has denied account access to DApp...
                }
            }
            // Legacy DApp Browsers
            else if (window.web3) {
                const web3 = new Web3(window.web3.currentProvider);
                this.setState({ web3state: web3 })
            }
            // Non-DApp Browsers
            else {
                //alert('No Dapp Supported Wallet Found');
                console.log("No Dapp Supported Wallet Found")
            }
        } catch (e) {

        }

    }

    Validation() {

        var isForm = true;
        var errors = {}
        if (this.state.alloc == "" || !this.state.alloc) {
            errors.alloc = "Please enter the Multiplier";
            isForm = false;
        }
        if (this.state.tokenSymbol == "") {
            errors.tokenSymbol = "Please enter the token A";
            isForm = false;
        }
        if (this.state.quoteTokenSymbol == "") {
            errors.quoteTokenSymbol = "Please enter the token B";
            isForm = false;
        }
        if (this.state.tokenAddresses == "") {
            errors.tokenAddresses = "Please enter the Token A Address";
            isForm = false;
        }
        if (this.state.quoteTokenAdresses == "") {
            errors.quoteTokenAdresses = "Please enter the Token B Address";
            isForm = false;
        }
        if (this.state.depositFee == "") {
            errors.depositFee = "Please enter the deposit Fee";
            isForm = false;
        }
        this.setState({
            errors: errors
        });
        return isForm;

    }
    editFarmData = async (e) => {

        const ABI = MasterchefABI ;
        const masterchef = config.masterchef;

        var masterContract = await new this.state.web3state.eth.Contract(ABI, masterchef);

        //  var updated = this.state.updated;
        var pid = this.state.pid;

        var allocPoint = this.state.alloc;
        allocPoint = allocPoint * 100;

        var depositFee = this.state.depositFee * 100;
        // alert(pid+'&'+allocPoint+'&'+depositFee)
        // return false;
        if(this.Validation()){
        await masterContract.methods.set(pid, allocPoint, depositFee, false).send({ from: this.state.user }).then(async (result) => {
            // console.log(result);
            e.preventDefault();
            const updateForm = {
                _id: this.state._id,
                pid: this.state.pid,
                risk: this.state.risk,
                alloc: this.state.alloc,
                isTokenOnly: this.state.isTokenOnly,
                lpAddresses: this.state.lpAddresses,
                tokenSymbol: this.state.tokenSymbol,
                tokenAddresses: this.state.tokenAddresses,
                quoteTokenSymbol: this.state.quoteTokenSymbol,
                quoteTokenAdresses: this.state.quoteTokenAdresses,
                depositFee: this.state.depositFee,
                user: this.state.user,
                file: this.state.file

            };
           console.log('updateform',updateForm);
            await this.props.updateForm(updateForm);
        }).catch(e => {
            //try again
            toast.error("Try Again", toasterOption);
            //  document.getElementById('logo_overlay').style.opacity='1';
            //  document.getElementById('loader_div').style.display='none';
        })
    }

    }
    componentWillReceiveProps(nextProps) {
        //console.log("next Props>>>>",nextProps)
        if (nextProps.record) {
            this.setState({
                _id: nextProps.record._id,
                pid: nextProps.record.pid,
                risk: nextProps.record.risk,
                alloc: nextProps.record.alloc,
                // isTokenOnly: nextProps.state.isTokenOnly,
                lpAddresses: nextProps.record.lpAddresses,
                tokenSymbol: nextProps.record.tokenSymbol,
                tokenAddresses: nextProps.record.tokenAddresses,
                quoteTokenSymbol: nextProps.record.quoteTokenSymbol,
                quoteTokenAdresses: nextProps.record.quoteTokenAdresses,
                depositFee: nextProps.record.depositFee,
                file: nextProps.record.file,
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-form-modal').modal('hide');

    }

    onChange = e => {
        if (e.target.id === 'alloc') {
            this.setState({ alloc: e.target.value * 100 });
        }
        if (e.target.id === 'pid') {
            this.setState({ pid: e.target.value });
        }
        if (e.target.id === 'form-update-tokenSymbol') {
            this.setState({ tokenSymbol: e.target.value });
        }
        if (e.target.id === 'form-update-quoteTokenSymbol') {
            this.setState({ quoteTokenSymbol: e.target.value });
        }
        if (e.target.id === 'form-update-tokenAddresses') {
            this.setState({ tokenAddresses: e.target.value });
        }
        if (e.target.id === 'form-update-quoteTokenAdresses') {
            this.setState({ quoteTokenAdresses: e.target.value });
        }
        if (e.target.id === 'form-update-depositFee') {
            this.setState({ depositFee: e.target.value });
        }
        if (e.target.id === 'lpAddresses') {
            this.setState({ lpAddresses: e.target.value });
        }

        console.log("Count 1: ", this.state.alloc, this.state.lpAddresses, this.state.pid)


    };
    onFileChange = event => {
        var name = event.target.value;
        // Update the state
        this.setState({ file: event.target.files[0] });
        this.setState({ imagepath: name });
        console.log(event.target.files[0]);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-form-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Farm</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="form-update-id"
                                        type="text"
                                        className="d-none" />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="alloc">Multipier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.alloc === "" ? this.state.alloc : this.state.alloc / 100}
                                                placeholder="Multipier (x)"
                                                id="alloc"
                                                type="text"
                                                error={errors.alloc}
                                                className={classnames("form-control", {
                                                    invalid: errors.alloc
                                                })} />
                                            <span className="text-danger">{errors.alloc}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.pid}
                                                // placeholder="Multipier (x)"
                                                id="pid"
                                                type="hidden"
                                                error={errors.pid}
                                                className={classnames("form-control", {
                                                    invalid: errors.pid
                                                })} />
                                            <span className="text-danger">{errors.pid}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Token A Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenSymbol}
                                                id="form-update-tokenSymbol"
                                                type="text"
                                                error={errors.tokenSymbol}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenSymbol
                                                })} />
                                            <span className="text-danger">{errors.tokenSymbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenSymbol ">Token B Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.quoteTokenSymbol}
                                                error={errors.quoteTokenSymbol}
                                                id="form-update-quoteTokenSymbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.quoteTokenSymbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.quoteTokenSymbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Token A Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenAddresses}
                                                error={errors.tokenAddresses}
                                                id="form-update-tokenAddresses"
                                                type="text"
                                                readOnly={true}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenAddresses
                                                })}
                                            />
                                            <span className="text-danger">{errors.tokenAddresses}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Token B Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.quoteTokenAdresses}
                                                error={errors.quoteTokenAdresses}
                                                id="form-update-quoteTokenAdresses"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.quoteTokenAdresses
                                                })}
                                            />
                                            <span className="text-danger">{errors.quoteTokenAdresses}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Deposit Fee</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.depositFee}
                                                error={errors.depositFee}
                                                id="form-update-depositFee"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.depositFee
                                                })}
                                            />
                                            <span className="text-danger">{errors.depositFee}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}

                                                value={this.state.lpAddresses}
                                                error={errors.lpAddresses}
                                                id="lpAddresses"
                                                type="hidden"
                                                className={classnames("form-control", {
                                                    invalid: errors.lpAddresses
                                                })} depositFee
                                            />
                                            <span className="text-danger">{errors.lpAddresses}</span>
                                        </div>

                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Add Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="update-form"

                                    className="btn btn-primary" onClick={this.editFarmData}>
                                    Update Farm
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormUpdateModal.propTypes = {
    updateForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateForm }
)(withRouter(FormUpdateModal));
