import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';

import Commision from "./components/pages/Commision";
import Setting from "./components/pages/setting";
import Liqutity from "./components/pages/Liqutity";
import Swapping from "./components/pages/Swapping";
import Farms from "./components/pages/Farms";
import Pools from "./components/pages/Pools";

if (localStorage.jwtToken) {

    console.log(localStorage.jwtToken,"tokenss")
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     store.dispatch(logoutUser());
    //     window.location.href = "./login";
    // }
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router basename={"/"}>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                            <Switch>
                                <PrivateRoute exact path="/commisions" component={Commision} />
                                <PrivateRoute exact path="/liqutity" component={Liqutity} />
                                <PrivateRoute exact path="/swapping" component={Swapping} />
                                <PrivateRoute exact path="/farms" component={Farms} />
                                <PrivateRoute exact path="/pools" component={Pools} />
                                <PrivateRoute exact path="/setting" component={Setting} />
                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}
// localStorage.clear();
export default App;
