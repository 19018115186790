import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import FormAddModal from "../partials/FormAddModal";
// import FormUpdateModal from "../partials/FormUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import config from "../../lib/config";
import formatAddress from "../../lib/formatAddress";

var backurl = config.baseurl;
var imageurl = backurl + "/liqutity/";

class Liqutity extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "fromaddress",
                text: "From Address",
                className: "fromaddress",
                align: "left",
                sortable: true,
                cell: record => {
                    var addr = formatAddress(record.fromaddress)
                    return addr;
                }
            },
            {
                key: "fromamount",
                text: "From Amount",
                className: "fromamount",
                align: "left",
                sortable: true
            },
            {
                key: "toaddress",
                text: "To Address",
                className: "toaddress",
                align: "left",
                sortable: true,
                cell: record => {
                    var addr = formatAddress(record.toaddress)
                    return addr;
                }
            },
            {
                key: "toamount",
                text: "To Amount",
                className: "toamount",
                align: "left",
                sortable: true
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Liqutity",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                pid: "",
                risk: "",
                isTokenOnly: "",
                lpAddresses: "",
                tokenSymbol: "",
                lpAddresses: "",
                tokenAddresses: "",
                quoteTokenSymbol: "",
                quoteTokenAdresses: "",
                depositFee: "",
                file: {},
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(backurl + "/api/liqutity-data")
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }

    editRecord(record) {
        //console.log("vavava11",record)
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post(backurl + "/api/liqutity-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    {/* <FormAddModal />
                    <FormUpdateModal record={this.state.currentRecord} /> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button> */}
                            {/* <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-form-modal"><FontAwesomeIcon icon={faPlus} /> Add Farm</button> */}
                            <h1 className="mt-2 text-primary">Liquidity</h1>
                            {/* <div className="table-responsive"> */}
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            {/* </div> */}
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Liqutity.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Liqutity);
