import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import IFOAddModal from "../partials/IFOAddModal";
import FormUpdateModal from "../partials/FormUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import config from "../../lib/config";

var backurl = config.backurl;
var backurl = config.baseurl;
var imageurl = backurl + "/forms/";

class Forms extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            // {
            //     key: "logoURI",
            //     text: "Image",
            //     className: "logoURI",
            //     align: "left",
            //     sortable: true,
            //     cell: record => {
            //         return (
            //             <img
            //                 src={imageurl + record.logoURI}
            //                 width={50}
            //                 alt='form'
            //             />
            //         )
            //     }
            // },
            {
                key: "ifotoken",
                text: "IFO Token",
                className: "ifotoken",
                align: "left",
                sortable: true
            },
            {
                key: "lptoken",
                text: "LP Token",
                className: "lptoken",
                align: "left",
                sortable: true
            },
            {
                key: "offeringtoken",
                text: "Offering Token",
                className: "offeringtoken",
                align: "left",
                sortable: true
            },
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true
            },
            {
                key: "subtitle",
                text: "Sub Title",
                className: "subtitle",
                align: "left",
                sortable: true
            },
            {
                key: "launchdate",
                text: "Launch Date",
                className: "launchdate",
                align: "left",
                sortable: true
            },
            {
                key: "launchtime",
                text: "Launch Time",
                className: "launchtime",
                align: "left",
                sortable: true
            },            
            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment>
            //                 <button
            //                     data-toggle="modal"
            //                     data-target="#update-form-modal"
            //                     className="btn btn-primary btn-sm"
            //                     onClick={() => this.editRecord(record)}
            //                     style={{ marginRight: '5px' }}>
            //                     <i className="fa fa-edit"></i>
            //                 </button>
            //                 <button
            //                     className="btn btn-danger btn-sm"
            //                     onClick={() => this.deleteRecord(record)}>
            //                     <i className="fa fa-trash"></i>
            //                 </button>
            //             </Fragment>
            //         );
            //     }
            // }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Forms",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id:"",
                ifotoken:"",
                lptoken:"",
                launchtime:"",
                launchdate:"",
                subtitle:"",
                name:"",
                offeringtoken:""
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(backurl + "/api/get-ifo")
            .then(res => {
                if(res && res.data && res.data.data){
                   this.setState({ records: res.data.data })
                }
            })
            .catch()
    }

    async editRecord(record) {        
        await this.setState({ currentRecord: record });
        console.log(this.state.currentRecord._id,'id');
    }

    deleteRecord(record) {
        axios
            .post(backurl + "/api/form-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <IFOAddModal />
                    <FormUpdateModal record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon  /></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-ifo-modal"><FontAwesomeIcon icon={faPlus} /> Add IFO</button>
                            <h1 className="mt-2 text-primary">IFO</h1>
                            <div className="table-responsive">
                                <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Forms.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Forms);
