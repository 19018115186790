import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import MasterchefABI from "../../ABI/MasterchefABI.json";
import factoryABI from "../../ABI/FactoryABI.json";
import IFOABI from "../../ABI/IfoABI.json"
import axios from "axios";
import moment from 'moment';
import config from "../../lib/config";
import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

var backurl = config.baseurl;


class IFOAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ifotoken:"",
            lptoken:"",
            offeringtoken:"",
            startblock:"",
            endblock:"",
            offeringamount:"",
            raisingamount:"",
            adminaddress:"",
            name:"",
            subtitle:"",
            description:"",
            launchdate:"",
            launchtime:"",
            caketoburn:"",
            url:"",
            lpname:"",
            decimal:"",
            image:"",
            loader: false,
            errors: {},
        };
        this.formatAddress = this.formatAddress.bind(this);
        this.updatefun = this.updatefun.bind(this);
    }

    componentDidMount() {
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-ifo-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    formatAddress(address) {
        try {
            var addr = address.substring(0, 10);
            var addr1 = address.substring(35, 42);
            var concat = addr + "...." + addr1;
            return concat;
        } catch (err) {
            return "";
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    Validation() {

        var isForm = true;
        var errors = {}
        if (this.state.ifotoken == "") {
            errors.ifotoken = "Please enter the IFO Token";
            isForm = false;
        }
        if (this.state.lptoken == "") {
            errors.lptoken = "Please enter the LP Token";
            isForm = false;
        }
        if (this.state.offeringtoken == "") {
            errors.offeringtoken = "Please enter the Offering Token";
            isForm = false;
        }

        if (this.state.startblock == "") {
            errors.startblock = "Please enter the  Start Block";
            isForm = false;
        }

        if (this.state.endblock == "") {
            errors.endblock = "Please enter the  End Block";
            isForm = false;
        }

        if (this.state.offeringamount == "") {
            errors.offeringamount = "Please enter the  Offering Amount";
            isForm = false;
        }

        if (this.state.raisingamount == "") {
            errors.offeringamount = "Please enter the  Raising Amount";
            isForm = false;
        }

        if (this.state.adminaddress == "") {
            errors.adminaddress = "Please enter the  Admin Address";
            isForm = false;
        }

        if (this.state.name == "") {
            errors.name = "Please enter the Name";
            isForm = false;
        }

        if (this.state.subtitle == "") {
            errors.subtitle = "Please enter the SubTitle";
            isForm = false;
        }

        if (this.state.description == "") {
            errors.description = "Please enter the Description";
            isForm = false;
        }

        if (this.state.launchdate == "") {
            errors.launchdate = "Please enter the Launch Date";
            isForm = false;
        }

        if (this.state.launchtime == "") {
            errors.launchtime = "Please enter the Launch Time";
            isForm = false;
        }

        if (this.state.url == "") {
            errors.url = "Please enter the Url";
            isForm = false;
        }

        if (this.state.lpname == "") {
            errors.lpname = "Please enter the LP Token Name";
            isForm = false;
        }
        console.log(errors,"------------")

        this.setState({
            errors: errors
        });
        return isForm;

    }

    convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

    async updatefun() {
        console.log("====hiiiii")
        if (this.Validation()) {
            this.setState({ loader: true });
            if(window.ethereum){
                try{
                    const web3 = new Web3(window.ethereum);
                    await window.ethereum.enable();
                    const accounts = await web3.eth.getAccounts();
                    var ifocontract = new web3.eth.Contract(IFOABI,config.ifocontract)
                    var adminaddress = await ifocontract.methods.ownerAddress().call();
                    if(adminaddress == accounts[0]){
                        let offer_amt = parseFloat(this.state.offeringamount)*1000000000000000000;
                        let riaising_amt = parseFloat(this.state.raisingamount)*1000000000000000000;
                        offer_amt = await this.convert(offer_amt);
                        riaising_amt = await this.convert(riaising_amt);
                        var update = await ifocontract.methods.createIfoSale(this.state.lptoken,this.state.offeringtoken,(this.state.startblock).toString(),(this.state.endblock).toString(),(offer_amt).toString(),(riaising_amt).toString(),this.state.adminaddress).send({ from: accounts[0] })
                        if(update && update.transactionHash && update.transactionHash!=null && update.transactionHash!=undefined && update.transactionHash!=""){
                             let ifoids = await ifocontract.methods.ifoIncrement().call();
                             var formData = new FormData();
                             formData.append('ifotoken', this.state.ifotoken);
                             formData.append('lptoken', this.state.lptoken);
                             formData.append('offeringtoken', this.state.offeringtoken);
                             formData.append('startblock', this.state.startblock);
                             formData.append('endblock', this.state.endblock);
                             formData.append('offeringamount', this.state.offeringamount);
                             formData.append('raisingamount', this.state.raisingamount);
                             formData.append('adminaddress', this.state.adminaddress);
                             formData.append('name', this.state.name);
                             formData.append('description', this.state.description);
                             formData.append('launchdate', this.state.launchdate);
                             formData.append('launchtime', this.state.launchtime);
                             formData.append('url', this.state.url);
                             formData.append('lpname', this.state.lpname);
                             formData.append('decimal', this.state.decimal);
                             formData.append('caketoburn', this.state.caketoburn);
                             formData.append('image', this.state.image);
                             formData.append('subtitle', this.state.subtitle);
                             formData.append('ifoids',ifoids);
                            axios
                                .post(backurl + "/api/update-IFO",formData)
                                .then(async res => {
                                    var update = await ifocontract.methods.getPresale(ifoids).call()
                                    var datas = {
                                        address : update,
                                        id : res.data.data._id
                                    }
                                    axios
                                        .post(backurl + "/api/update-ifoaddress",datas)
                                        .then(res1 => {
                                         this.setState({ loader: false });
                                         window.location.reload()
                                        })
                                        .catch(()=>{
                                            this.setState({ loader: false });
                                        })
                                })
                                .catch(()=>{
                                    this.setState({ loader: false });
                                })
                        }else{
                            this.setState({ loader: false });
                        }  
                    }else{
                        this.setState({ loader: false });
                        var errors = {}
                        errors.owneraddress = "Please Login  Admin Address";
                        this.setState({
                            errors: errors
                        });
                    } 
                }catch(err){
                    console.log(err,"=======errr")
                    this.setState({ loader: false });
                }
            }else{
                this.setState({ loader: false });
            }
            
        }
    }

    dateFormat(date, format) {

        if (date && date !== '' && format && format !== '') {
            var cDate = moment(date).format(format);
            return cDate;
        } else {
            return '-';
        }
    }

    pageChange(pageData) {
    }

    onFileChange = event => {
       // var name = event.target.value;
        // Update the state
        this.setState({ image: event.target.files[0] });
        //console.log(name);
    };


    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-ifo-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add IFO</h4>
                                <button type="button" onClick={this.clearData} className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                 <form noValidate id="add-ifo">
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="ifotoken">IFO Token</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.ifotoken}
                                                    placeholder="IFO Token"
                                                    id="ifotoken"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.ifotoken
                                                    })} />
                                                <span className="text-danger">{errors.ifotoken}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="lptoken">LP Token</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.lptoken}
                                                    placeholder="LP Token"
                                                    id="lptoken"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.lptoken
                                                    })} />
                                                <span className="text-danger">{errors.lptoken}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="offeringtoken">Offering Token</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.offeringtoken}
                                                    placeholder="Offering Token"
                                                    id="offeringtoken"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.offeringtoken
                                                    })} />
                                                <span className="text-danger">{errors.offeringtoken}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="startblock">Start Block</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.startblock}
                                                    placeholder="Start Block"
                                                    id="startblock"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.startblock
                                                    })} />
                                                <span className="text-danger">{errors.startblock}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="endblock">End Block</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.endblock}
                                                    placeholder="End Block"
                                                    id="endblock"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.endblock
                                                    })} />
                                                <span className="text-danger">{errors.endblock}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="launchdate">Launch Date</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.launchdate}
                                                    placeholder="Ex: Mar. 25 "
                                                    id="launchdate"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.launchdate
                                                    })} />
                                                <span className="text-danger">{errors.launchdate}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="launchtime">Launch Time</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.launchtime}
                                                    placeholder="Ex: 9AM IST "
                                                    id="launchtime"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.launchtime
                                                    })} />
                                                <span className="text-danger">{errors.launchtime}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="name">Name</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.name}
                                                    placeholder="Name"
                                                    id="name"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.name
                                                    })} />
                                                <span className="text-danger">{errors.name}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="tokenname">Reward Token Name</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.tokenname}
                                                    placeholder="Reward Token Name"
                                                    id="tokenname"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.tokenname
                                                    })} />
                                                <span className="text-danger">{errors.tokenname}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="subtitle">Sub Title</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.subtitle}
                                                    placeholder="Sub Title"
                                                    id="subtitle"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.subtitle
                                                    })} />
                                                <span className="text-danger">{errors.subtitle}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="description">Description</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.description}
                                                    placeholder="Description"
                                                    id="description"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.description
                                                    })} />
                                                <span className="text-danger">{errors.description}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="offeringamount">Offering Amount</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.offeringamount}
                                                    placeholder="Offering Amount Block"
                                                    id="offeringamount"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.offeringamount
                                                    })} />
                                                <span className="text-danger">{errors.offeringamount}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="raisingamount">Raising Amount</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.raisingamount}
                                                    placeholder="Raising Amount"
                                                    id="raisingamount"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.raisingamount
                                                    })} />
                                                <span className="text-danger">{errors.raisingamount}</span>
                                            </div>
                                        </div>
                                       {/* <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="caketoburn">Cake To Burn</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.caketoburn}
                                                    placeholder="Cake To Burn"
                                                    id="caketoburn"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.caketoburn
                                                    })} />
                                                <span className="text-danger">{errors.caketoburn}</span>
                                            </div>
                                        </div> */}
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="url">Project Site Url</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.url}
                                                    placeholder="Site Url"
                                                    id="url"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.url
                                                    })} />
                                                <span className="text-danger">{errors.url}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="lpname">LP Currency Name</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.lpname}
                                                    placeholder="LP Token Name"
                                                    id="lpname"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.lpname
                                                    })} />
                                                <span className="text-danger">{errors.lpname}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="decimal">Token Decimal</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.decimal}
                                                    placeholder="Ex: 18 "
                                                    id="decimal"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.decimal
                                                    })} />
                                                <span className="text-danger">{errors.decimal}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="adminaddress">Admin Address</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.adminaddress}
                                                    placeholder="Admin Address"
                                                    id="adminaddress"
                                                    type="text"
                                                    className={classnames("form-control", {
                                                        invalid: errors.adminaddress
                                                    })} />
                                                <span className="text-danger">{errors.adminaddress}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="image">Add Image</label>
                                            </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                error={errors.image}
                                                id="image"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })}
                                            />
                                            <span className="text-danger">{errors.image}</span>
                                            <span className="text-danger">{errors.owneraddress}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.clearData} className="btn btn-secondary" data-dismiss="modal">Close</button>
                               {this.state.loader?
                                <a
                                    form="add-ifo"
                                    className="btn btn-primary">
                                     Processing..., Please Wait...!
                                </a>
                                :
                                <a
                                    form="add-ifo"
                                    className="btn btn-primary" onClick={this.updatefun}>
                                     Add IFO
                                </a>
                               }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

IFOAddModal.propTypes = {
    addForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addForm }
)(withRouter(IFOAddModal));
